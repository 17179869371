exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-excursions-js": () => import("./../../../src/pages/excursions.js" /* webpackChunkName: "component---src-pages-excursions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tours-js": () => import("./../../../src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */),
  "component---src-templates-excursion-js": () => import("./../../../src/templates/excursion.js" /* webpackChunkName: "component---src-templates-excursion-js" */),
  "component---src-templates-tour-js": () => import("./../../../src/templates/tour.js" /* webpackChunkName: "component---src-templates-tour-js" */)
}

